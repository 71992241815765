<template>
  <!-- Page Content -->
  <div class="my-2">
    <h2 class="my-4 text-4xl font-semibold">Manage Menu</h2>

    <!-- CONTENT -->
    <o-button
      type="button"
      class="float-right"
      @click="add()"
      v-if="hasAccess('downloads', 'add')"
      >Add</o-button
    >
    <!-- Downloads Table Content -->
    <table
      class="min-w-max w-full mt-5 table-auto paginated table-fixed" style="padding: 10px"
      :sticky-header="true"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      :mobile-cards="true"
      :paginated="true"
      :per-page="10"
    >
      <!-- Downloads Table Content Header -->
      <thead >
        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th
            @click="sort('title')"
            class="py-3 px-6 text-center cursor-pointer"
          >
            Title
          </th>
          <th
            @click="sort('order')"
            class="py-3 px-6 text-center cursor-pointer"
          >
            Order
          </th>
          <th
            @click="sort('active')"
            class="py-3 px-6 text-center cursor-pointer"
          >
            Visible
          </th>
          <th
            class="py-3 px-6 text-center"
          >
            Action
          </th>
        </tr>
      </thead>
      <!-- Downloads Table Content Body -->
      <tbody class="text-gray-600 text-sm font-light">
        <tr
          v-for="item in sortedItems"
          :key="item.id"
          class="border-b border-gray-200 hover:bg-gray-100"
        >
          <td
            class="py-3 px-3 text-center"
          >
            {{ item.title }}
          </td>
          <td
            class="py-3 px-3 text-center"
          >
            {{ item.order }}
          </td>
          <td
            class="py-3 px-3 text-center"
          >
            {{ item.active ? "Yes" : "No" }}
          </td>
          <td class="py-3 px-3 text-center flex justify-center align-center">
            <a
              href=""
              v-if="hasAccess('downloads', 'edit')"
              @click.prevent="edit(item)"
              class="w-4 mr-2 ml-2 transform hover:text-purple-500 hover:scale-110"
              >
              <EditIcon />
            </a>
            <a
              href=""
              v-if="hasAccess('downloads', 'delete')"
              @click.prevent="remove(item)"
              class="w-4 mr-2 ml-2 transform hover:text-purple-500 hover:scale-110"
              >
              <DeleteForeverIcon />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Downloads Table Content Pagination -->
    <p class="pagination-container">
      <button
        v-if="currentPage != 1"
        @click="prevPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        &lt; Prev
      </button>

      <button
        v-if="(this.currentPage*this.pageSize) < this.items.length"
        @click="nextPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        Next &gt;
      </button>
    </p>
    <!-- Downloads Modal Content -->
    <o-modal
      :active.sync="showEditModal"
      :trapFocus="true"
      scroll="keep"
      :width="400"
    >
      <form action="" class="mb-5">
        <div class="modal-card">
          <header class="modal-card-head p-3">
            <p class="modal-card-title text-white font-semibold">
              {{ editing ? "Edit Menu Item" : "Add Menu Item" }}
            </p>
          </header>
          <section class="modal-card-body p-3">
            <div class="mt-5">
              <label class="w-full">Title</label>
              <input
                type="text"
                v-model="item.title"
                class="lb-admin-form-input mt-2 rounded"
                required
              />
            </div>
            <div class="mt-5">
              <label class="w-full">Order</label>
              <input
                type="number"
                v-model="item.order"
                class="lb-admin-form-input mt-2 rounded"
                required
              />
            </div>
            <div class="mt-5">
              <label class="w-full"
                ><input
                  type="checkbox"
                  v-model="item.active"
                  class="lb-admin-form-input"
                />
                Visible?</label
              >
            </div>
            <div class="mt-5">
              <label class="w-full"
                ><input
                  type="checkbox"
                  v-model="item.showupload"
                  class="lb-admin-form-input"
                />
                Upload File?</label
              >
            </div>

            <div class="mt-5" v-if="!item.showupload">
              <label class="w-full">URL</label>
              <input
                type="url"
                v-model="item.url"
                class="lb-admin-form-input mt-2 rounded"
                required
              />
            </div>
            <div class="mt-5" v-if="!item.showupload">
              <label class="w-full"
                ><input
                  type="checkbox"
                  v-model="item.showinnewtab"
                  class="lb-admin-form-input"
                />
                Show in new tab?</label
              >
            </div>
            <div class="mt-5" v-if="item.showupload && !editing">
              <label class="w-full">Upload File</label>
              <UploadFile v-model="item.filePath" />
            </div>
            <div class="mt-5">
              <label class="w-full"
                ><input
                  type="checkbox"
                  v-model="item.loggedinonly"
                  class="lb-admin-form-input"
                />
                Logged in users only?</label
              >
            </div>
          </section>
          <hr />
          <footer class="modal-card-foot mt-5">
            <o-button
              class="float-right mr-3"
              type="button"
              @click="showEditModal = false"
              >Cancel</o-button
            >
            <o-button
              type="submit"
              class="mr-3 mb-3 float-right"
              variant="primary"
              @click="save"
              >{{ editing ? "Update" : "Add" }}</o-button
            >
          </footer>
        </div>
      </form>
    </o-modal>
  </div>
</template>

<script>
import { downloadsCollection } from "../../firebase.js";
import firebase from "firebase";
import UploadFile from "../../components/admin/UploadFile";
import permissionsMixin from "@/mixins/permissions";

import EditIcon from "vue-material-design-icons/PencilOutline";
import DeleteForeverIcon from "vue-material-design-icons/DeleteForever";

export default {
  name: "AdminDownloads",
  mixins: [permissionsMixin],
  components: {
    UploadFile,
    EditIcon,
    DeleteForeverIcon,
  },
  data: () => ({
    currentSort:'order',
    currentSortDir:'asc',
    pageSize:10,
    currentPage:1,
    items: [],
    showEditModal: false,
    editing: false,
    item: {},
  }),
  methods: {
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.items.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    add() {
      this.editing = false;
      this.item = {};
      this.showEditModal = true;
    },
    save() {
      if (this.editing) {
        downloadsCollection
          .doc(this.item.id)
          .update({
            title: this.item.title,
            order: parseInt(this.item.order, 10),
            modified: firebase.firestore.Timestamp.now(),
            active: this.item.active ? true : false,
            showupload: this.item.showupload ? true : false,
            url: this.item.url,
            showinnewtab: this.item.showinnewtab ? true : false,
            loggedinonly: this.item.loggedinonly ? true : false,
          })
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Download updated",
            });
            this.showEditModal = false;
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: "Error updated Download",
              text: error.message,
            });
          });
      } else {
        this.item.created = firebase.firestore.Timestamp.now();
        this.item.modified = firebase.firestore.Timestamp.now();
        this.item.order = parseInt(this.item.order, 10);
        this.item.active = this.item.active ? true : false;
        this.item.showupload = this.item.showupload ? true : false;
        this.item.showinnewtab = this.item.showinnewtab ? true : false;
        this.item.loggedinonly = this.item.loggedinonly ? true : false;
        downloadsCollection
          .add(this.item)
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Download added",
            });
            this.showEditModal = false;
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: "Error adding Download",
              text: error.message,
            });
          });
      }
    },
    edit(item) {
      this.editing = true;
      this.item = item;
      this.showEditModal = true;
    },
    remove(item) {
      downloadsCollection
        .doc(item.id)
        .delete()
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Download deleted",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error deleting Download",
            text: error.message,
          });
        });
    },
    fetchItems() {
      downloadsCollection.onSnapshot((snap) => {
        let items = [];
        snap.forEach((doc) => {
          items.push({
            id: doc.id,
            title: doc.data().title,
            order: doc.data().order,
            active: doc.data().active,
            created: doc.data().created,
            modified: doc.data().modified,
            filePath: doc.data().filePath,
            showupload: doc.data().showupload || true,
            url: doc.data().url || null,
            showinnewtab: doc.data().showinnewtab || false,
            loggedinonly: doc.data().loggedinonly || false,
          });
        });
        items = items.sort((a, b) => {
          return a.order - b.order;
        });
        this.items = items;
      });
    },
  },
  computed: {
    sortedItems:function() {
      let items = this.items
      return items.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    }
  },
  created() {
    this.fetchItems();
  },
};
</script>

<style scoped>
.modal-card-head {
  background-color: #aa9461;
}
.o-btn {
  @apply bg-primary;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}
</style>
