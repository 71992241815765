<template>
  <div>
    <p v-if="!image" class="text-sm text-gray-500">No file uploaded.</p>
    <a v-if="image" class="text-sm text-gray-500" :href="image" target="_blank"
      >View File</a
    >
    <div>
      <o-button
        class="bg-primary px-4 py-2 text-white font-semibold rounded mt-3"
        variant="primary"
        v-if="image"
        size="is-small"
        @click="removeImage()"
        >Remove</o-button
      >
    </div>
    <o-upload v-model="file" @input="uploadImage" v-if="!image">
      <o-button tag="a">
        <span>Upload</span>
      </o-button>
    </o-upload>
    <div v-if="showUploadLoading" type="is-info">
      {{ showUploadProgress.toFixed(0) }}%
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/storage";

export default {
  name: "UploadFile",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showUpload: false,
      showUploadLoading: false,
      imageFile: null,
      showUploadProgress: 0,
      image: null,
      file: null,
    };
  },
  watch: {
    value(value) {
      this.image = value;
    },
  },
  mounted() {
    if (this.value) {
      this.image = this.value;
    } else {
      this.$emit("input", null);
    }
  },
  methods: {
    removeImage() {
      this.image = null;
      this.$emit("input", null);
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    uploadImage(file) {
      let vm = this;
      this.showUploadLoading = true;
      let parts = this.file.name.split(".");
      let newFileName = `${this.uuidv4()}.${parts[parts.length - 1]}`;
      const storageRef = firebase
        .storage()
        .ref(`files/${newFileName}`)
        .put(file);
      storageRef.on(
        "state_changed",
        (snapshot) => {
          vm.showUploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => console.log(error.message),
        () => {
          vm.showUploadProgress = 100;
          vm.showUploadLoading = false;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            vm.image = url;
            vm.$emit("input", vm.image);
          });
        }
      );
    },
  },
};
</script>


